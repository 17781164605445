import {
  BloodTest,
  ConvertedParticipants,
  Intervention,
  ParticipantInterface,
} from "@/utils/Interfaces/Participants";
import moment, { Moment } from "moment-timezone";
import { interPolatedValues } from "./ChartConfig";
import { SELF_CONSUMED_BOLUS_TYPES, zeroPercent } from "./Constants";
import { IBolusGraph } from "./Interfaces/Chart";
export const objectToArray = (data: any) => {
  return Object.entries(data)
    .map((item: any) => {
      return { key: item[0], ...item[1] };
    })
    .sort((a, b) => {
      return a.lastName.localeCompare(b.lastName);
    });
};

export const convertToArray = (data: any) => {
  return Object.entries(data)
    .map((item: any) => {
      return { key: item[0], ...item[1] };
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
};
const calculateMeanAndDeviation = (data: Array<number>) => {
  if (!data || data.length === 0) return 0;
  const N = data.length; // Population size
  const mean = Number(
    (data.reduce((sum, value) => sum + value, 0) / N).toFixed(1)
  ); // Mean glucose
  const variance =
    data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / N; // Variance
  const standardDeviation = Number(Math.sqrt(variance).toFixed(1)); // SD of glucose

  const cv = Number(((100 * standardDeviation) / mean).toFixed(2)); // Coefficient of Variation (CV)

  return {
    mean,
    standardDeviation,
    cv,
  };
};

export const convertGraphData = (basalData: any, bolusData: any) => {
  let tclPercentage = zeroPercent;
  let belowRangePercentage = zeroPercent;
  let aboveRangePercentage = zeroPercent;
  let tirPercentage = zeroPercent;
  let rangeBetween3And7Percentage = zeroPercent;
  let rangeBelow3Percentage = zeroPercent;
  let rangeAbove13Percentage = zeroPercent;
  let rangeAbove16Percentage = zeroPercent;
  let tclCount = 0;
  let belowRangeCount = 0;
  let aboveRangeCount = 0;
  let tirCount = 0;
  let totalEntries = 0;
  let targetGlucose = 0;
  let totalCarbAmount = 0;
  let rangeBetween3And7 = 0;
  let rangeBelow3 = 0;
  let rangeAbove13 = 0;
  let rangeAbove16 = 0;
  let meanGlucose = 0;
  let sdGlucose = 0;
  let cvGlucose = 0;
  let totalBloodGlucose = 0;
  let totalValuesForGlucose: Array<number> = [];
  let meanAndDeviationValues: any;
  const glucoseData: Array<{
    x: string | Date | number;
    y: number;
  }> = [];
  const closedLoopBasal: Array<{
    x: string;
    y: number;
  }> = [];
  const bolus: Array<{
    x: string;
    y: number;
    icr: string;
    bolusAmount: number;
    iob: string;
    mealCarbAmount: number;
    algoTotalBolus: string;
  }> = [];
  const openLoopBasal: Array<{
    x: string;
    y: number;
  }> = [];
  let openLoop = 0;
  if (bolusData) {
    const transformedBolusData = Object.entries(bolusData)
      .map((item: any) => {
        return { key: item[0], ...item[1] };
      })
      .sort((objA, objB) => objA.requestedAt - objB.requestedAt);
    transformedBolusData.forEach((item: any, idx) => {
      if (
        item.bolusAmountConfirmed !== 0 &&
        (item.confirmedAt !== 0 || !item.hasOwnProperty("bolusType"))
      ) {
        // TODO NOT NEEDED
        bolus.push({
          x: item.requestedAt !== 0 ? item.requestedAt : item.timestamp,
          y: item.algoTotalBolusAmountRounded,
          icr: item.mealCarbAmount > 0 ? item.icr : 0,
          iob: item.iob,
          algoTotalBolus: item.algoTotalBolusAmountRounded,
          bolusAmount: item.bolusAmountConfirmed,
          mealCarbAmount: item.mealCarbAmount,
        });
        totalCarbAmount += item.mealCarbAmount;
      }
    });
  }
  if (basalData) {
    const transformedData = Object.entries(basalData)
      .map((item: any) => {
        return { key: item[0], ...item[1] };
      })
      .sort((objA, objB) => objA.requestedAt - objB.requestedAt);
    targetGlucose =
      getLastEntryFromAnArrayOfObject(transformedData, "targetBasal") ?? 0;
    transformedData.forEach((item: any) => {
      if (item.bloodGlucose > 0) {
        totalEntries++;
        openLoop += item.bloodGlucose;
        totalValuesForGlucose.push(item.bloodGlucose);
      }
      if (item.autoMode && item.bloodGlucose > 0) tclCount++;
      if (item.bloodGlucose < 3.9 && item.bloodGlucose > 0) belowRangeCount++;
      if (item.bloodGlucose > 10) aboveRangeCount++;
      if (item.bloodGlucose >= 3.9 && item.bloodGlucose <= 10) tirCount++;
      if (item.bloodGlucose >= 3.9 && item.bloodGlucose <= 7.8)
        rangeBetween3And7++;
      if (item.bloodGlucose < 3 && item.bloodGlucose > 0) rangeBelow3++;
      if (item.bloodGlucose > 13.9) rangeAbove13++;
      if (item.bloodGlucose > 16.7) rangeAbove16++;
      const a = moment(item.requestedAt);
      const zeroSeconds = a.seconds(0).milliseconds(0);
      glucoseData.push(
        item.bloodGlucose > 0
          ? {
              x: zeroSeconds.valueOf(),
              y: item.bloodGlucose,
            }
          : {
              x: zeroSeconds.valueOf(),
              y: NaN,
            }
      );
      openLoopBasal.push({
        x: moment(item.requestedAt).toISOString(),
        y: item.insPumpBasalRate,
      });
      closedLoopBasal.push({
        x: moment(item.requestedAt).toISOString(),
        y: item.basal,
      });
    });
    if (totalEntries > 0) {
      tclPercentage = calcPercentage(tclCount, totalEntries);
      belowRangePercentage = calcPercentage(belowRangeCount, totalEntries);
      aboveRangePercentage = calcPercentage(aboveRangeCount, totalEntries);
      tirPercentage = calcPercentage(tirCount, totalEntries);

      rangeBelow3Percentage = calcPercentage(rangeBelow3, totalEntries);
      rangeBetween3And7Percentage = calcPercentage(
        rangeBetween3And7,
        totalEntries
      );
      rangeAbove13Percentage = calcPercentage(rangeAbove13, totalEntries);
      rangeAbove16Percentage = calcPercentage(rangeAbove16, totalEntries);
      meanAndDeviationValues = calculateMeanAndDeviation(totalValuesForGlucose);
    }
  }

  return {
    glucoseData: interPolatedValues(glucoseData),
    openLoopBasal,
    closedLoopBasal: closedLoopBasal,
    bolus: bolus,
    tclPercentage,
    belowRangePercentage,
    aboveRangePercentage,
    tirPercentage,
    targetGlucose,
    totalCarbAmount,
    rangeBetween3And7Percentage,
    rangeBelow3Percentage,
    rangeAbove13Percentage,
    rangeAbove16Percentage,
    meanGlucose: meanAndDeviationValues?.mean,
    sdGlucose: meanAndDeviationValues?.standardDeviation,
    cvGlucose: meanAndDeviationValues?.cv,
  };
};

export const createOutsideMealBolusGraphData = (
  data: Array<Record<string, any>>
) => {
  const mealOutsideBolusData: Array<IBolusGraph> = [];
  const selfConsumedBolusData: Array<IBolusGraph> = [];

  const pointerChartData: Array<{
    x: number;
    y: number;
    mealCarbAmount: number;
  }> = [];
  if (data) {
    const transformedBolusData = data
      .slice()
      .sort((objA, objB) => objA.createdAt - objB.createdAt);
    transformedBolusData.forEach((item: any, idx) => {
      if (
        item?.bolusType === SELF_CONSUMED_BOLUS_TYPES.Correction ||
        item?.bolusType === SELF_CONSUMED_BOLUS_TYPES.Meal
      ) {
        selfConsumedBolusData.push({
          x: item.createdAt,
          y: item.bolusAmount,
          bolusAmount: item.bolusAmount,
          icr: item.icr,
          iob: item.iob || 0,
          algoTotalBolus: item.algoTotalBolusAmountRounded || "0",
          mealCarbAmount: item?.mealCarbAmount || 0,
        });
      } else {
        mealOutsideBolusData.push({
          x: item.createdAt,
          y: item.bolusAmount,
          bolusAmount: item.bolusAmount,
          icr: item.icr,
          iob: item.iob || 0,
          algoTotalBolus: item.algoTotalBolusAmountRounded || "0",
          mealCarbAmount: item?.mealCarbAmount || 0,
        });
      }

      pointerChartData.push({
        x: item?.createdAt,
        y: 21,
        mealCarbAmount: item?.mealCarbAmount,
      });
    });
  }
  return {
    pumpInfusionBolusData: mealOutsideBolusData,
    pointerChartData,
    selfConsumedBolusData,
  };
};

export const createExerciseGraphData = (
  exerciseData: Array<Record<string, any>>
) => {
  const exerciseGraphData: Array<{
    x: string | number | null;
    y: number | null;
    mode: boolean | null;
  }> = [];

  if (exerciseData) {
    exerciseData.forEach((data, idx) => {
      if ((idx === 0 && data.mode) || (idx % 2 === 1 && !data.mode)) {
        exerciseGraphData.push({ x: data.timestamp, y: 12, mode: data.mode });
      } else if (idx % 2 === 0 && data.mode) {
        exerciseGraphData.push({ x: null, y: null, mode: null });
        exerciseGraphData.push({ x: data.timestamp, y: 12, mode: data.mode });
      }
    });
  }
  return exerciseGraphData;
};

export const calculateOverviewOutcomes = (basalData: any, bolusData: any) => {
  let tclPercentage = zeroPercent;
  let belowRangePercentage = zeroPercent;
  let aboveRangePercentage = zeroPercent;
  let tirPercentage = zeroPercent;
  let tclCount = 0;
  let belowRangeCount = 0;
  let aboveRangeCount = 0;
  let tirCount = 0;
  let totalEntries = 0;
  let targetGlucose = 0;
  let totalCarbAmount = 0;

  const closedLoopBasal: Array<{
    x: string;
    y: number;
  }> = [];
  const bolus: Array<{
    x: string;
    y: number;
    icr: string;
    bolusAmount: number;
    iob: string;
    mealCarbAmount: number;
    algoTotalBolus: string;
  }> = [];

  if (bolusData) {
    const transformedBolusData = Object.entries(bolusData)
      .map((item: any) => {
        return { key: item[0], ...item[1] };
      })
      .sort((objA, objB) => objA.requestedAt - objB.requestedAt);
    transformedBolusData.forEach((item: any, idx) => {
      if (
        item.bolusAmountConfirmed !== 0 &&
        (item.confirmedAt !== 0 || !item.hasOwnProperty("bolusType"))
      ) {
        bolus.push({
          x: item.requestedAt !== 0 ? item.requestedAt : item.timestamp,
          y: item.algoTotalBolusAmountRounded,
          icr: item.mealCarbAmount > 0 ? item.icr : 0,
          iob: item.iob,
          algoTotalBolus: item.algoTotalBolusAmountRounded,
          bolusAmount: item.bolusAmountConfirmed,
          mealCarbAmount: item.mealCarbAmount,
        });
        totalCarbAmount += item.mealCarbAmount;
      }
    });
  }
  if (basalData) {
    const transformedData = Object.entries(basalData)
      .map((item: any) => {
        return { key: item[0], ...item[1] };
      })
      .sort((objA, objB) => objA.requestedAt - objB.requestedAt);
    targetGlucose =
      getLastEntryFromAnArrayOfObject(transformedData, "targetBasal") ?? 0;

    transformedData.forEach((item: any) => {
      if (item.bloodGlucose > 0) totalEntries++;
      if (item.autoMode && item.bloodGlucose > 0) tclCount++;
      if (item.bloodGlucose < 3.9 && item.bloodGlucose > 0) belowRangeCount++;
      if (item.bloodGlucose > 10) aboveRangeCount++;
      if (item.bloodGlucose >= 3.9 && item.bloodGlucose <= 10) tirCount++;
      const a = moment(item.requestedAt);
      const zeroSeconds = a.seconds(0).milliseconds(0);
      closedLoopBasal.push({
        x: moment(item.requestedAt).toISOString(),
        y: item.basal,
      });
    });

    if (totalEntries > 0) {
      tclPercentage = calcPercentage(tclCount, totalEntries);
      belowRangePercentage = calcPercentage(belowRangeCount, totalEntries);
      aboveRangePercentage = calcPercentage(aboveRangeCount, totalEntries);
      tirPercentage = calcPercentage(tirCount, totalEntries);
    }
  }

  return {
    closedLoopBasal: closedLoopBasal,
    bolus: bolus,
    tclPercentage,
    belowRangePercentage,
    aboveRangePercentage,
    tirPercentage,
  };
};

export const calcPercentage = (count: number, length: number) => {
  const data = ((count / length) * 100).toFixed(1);
  return isNaN(+data) ? "0.0%" : `${data}%`;
};

export const avgCalcPercentage = (count: number, length: number) => {
  const data = (count / length).toFixed(1);
  return isNaN(+data) ? "0.0%" : `${data}%`;
};

export const avgCalculator = (count: number, length: number) => {
  const data = Number((count / length).toFixed(1)) || 0;
  return isNaN(data) ? 0 : data;
};

export const interventionParser = (data: ConvertedParticipants) => {
  const newObj = JSON.parse(JSON.stringify(data));
  if (newObj?.intervention) {
    newObj.intervention = newObj.intervention.map(
      (interventionData: {
        name: string;
        startDate?: string;
        endDate?: string;
        key: string;
        timestamp?: number;
      }) => {
        return {
          key: interventionData.key,
          name: interventionData.name,
          startDate: interventionData?.startDate
            ? new Date(interventionData.startDate)
            : null,
          endDate: interventionData?.endDate
            ? new Date(interventionData.endDate)
            : null,
          timestamp: interventionData?.timestamp
            ? interventionData.timestamp
            : null,
        };
      }
    );
  }
  if (newObj?.bloodTest) {
    newObj.bloodTest = JSON.parse(newObj.bloodTest).map(
      (bloodTestItem: any) => {
        return {
          ...bloodTestItem,
          testDate: bloodTestItem.testDate
            ? new Date(bloodTestItem.testDate)
            : null,
        };
      }
    );
  }

  newObj.dob = data?.dob ? new Date(JSON.parse(data.dob)) : null;
  newObj.diagnoseDate = data?.diagnoseDate
    ? new Date(JSON.parse(data.diagnoseDate as string))
    : null;
  newObj.admissionDate = data?.admissionDate
    ? new Date(JSON.parse(data.admissionDate as string))
    : null;
  newObj.studyEndDate = data?.studyEndDate
    ? new Date(JSON.parse(data.studyEndDate as string))
    : null;
  newObj.gender = data.gender ? data.gender : "";
  newObj.id = data.id ? data.id : undefined;
  newObj.height = data.height ? data.height : undefined;
  newObj.studyId = data.studyId ? data.studyId : undefined;
  newObj.pin = data.pin ? data.pin : undefined;
  newObj.isLogin = data?.isLogin ? data.isLogin : false;
  newObj.weight = data?.weight ? data.weight : undefined;
  return newObj;
};

export const convertParticipantToArray = (
  data: Record<string, ConvertedParticipants>
) => {
  return Object.entries(data)?.map((item) => {
    return {
      key: item[0],
      ...interventionParser(item[1]),
    };
  });
};

const removeUndefinedValuesFromObject = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    (obj[key] === null || obj[key] === undefined) && delete obj[key];
  });
  return obj;
};

export const convertInitialParticipantsData = (data: ParticipantInterface) => {
  try {
    const participantObject: any = {
      ...data,
      dob: data?.dob ? JSON.stringify(data.dob) : null,
      diagnoseDate: data?.diagnoseDate
        ? JSON.stringify(data.diagnoseDate)
        : null,
      admissionDate: data?.admissionDate
        ? JSON.stringify(data.admissionDate)
        : null,
      studyEndDate: data?.studyEndDate
        ? JSON.stringify(data.studyEndDate)
        : null,
    };

    participantObject["bloodTest"] = data.bloodTest
      ? JSON.stringify(data.bloodTest)
      : JSON.stringify([]);

    participantObject["intervention"] = data.intervention
      ? data.intervention.reduce((acc: Record<string, any>, curr: any) => {
          acc[curr.key] = {
            name: curr.name,
            startDate: curr?.startDate ? moment(curr.startDate).unix() : null,
            endDate: curr?.startDate ? moment(curr.endDate).unix() : null,
            timestamp: curr?.timestamp || null,
          };
          return acc;
        }, {})
      : {};

    return removeUndefinedValuesFromObject(participantObject);
  } catch (err) {
    if (err) throw err;
  }
};

export const randomStringGenerator = (length: number): string => {
  const charset =
    "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = 0; i < length; i++)
    result += charset[Math.floor(Math.random() * charset.length)];
  return result;
};

export const convertInitialModalData = (data: any) => {
  return data.map((item: any, index: any) => {
    return {
      name: item.name,
      startDate: new Date(item.startDate),
      endDate: new Date(item.endDate),
    };
  });
};

export const convertIdCheckToArray = (
  data: ConvertedParticipants,
  studyId: string | undefined
) => {
  const convertedArray = Object.entries(data).map((item: any) => {
    return { UId: item[0], ...item[1] };
  });
  return findObject(convertedArray, studyId);
};

const findObject = (
  arr: ConvertedParticipants[],
  studyId: string | undefined
) => {
  const element = arr.find((value) => {
    return value.studyId === studyId;
  });
  return typeof element === "undefined" ? false : true;
};

export const generateLabels = (
  currentDate: Date | string | number | Moment
) => {
  const labels = [];
  for (let h = 0; h < 24; h++) {
    const timeDate = moment(currentDate);
    timeDate.hours(h);
    labels.push(timeDate.toISOString());
  }
  return labels;
};

const getLatestBloodTest = (bloodTests: BloodTest[]) => {
  const sortedTests = [...bloodTests].sort(
    (a, b) =>
      Date.parse(b.testDate as string) - Date.parse(a.testDate as string)
  );
  const latestTest = sortedTests[0];
  return latestTest?.name ? `${latestTest.name}% ` : "-";
};

const getIntervention = (interventions: Intervention[]) => {
  const currentDate = moment();
  let foundIntervention: {
    name: string;
    startDate: Date | null;
    endDate: Date | null;
    diffStart?: any;
    diffEnd?: any;
    key: string;
  } | null = null;

  for (const intervention of interventions) {
    const startDate = moment(intervention.startDate);
    const endDate = moment(intervention.endDate);
    if (
      currentDate.isSameOrAfter(startDate) &&
      currentDate.isSameOrBefore(endDate)
    ) {
      foundIntervention = intervention;
      break;
    } else {
      const diffStart = Math.abs(currentDate.diff(startDate));
      const diffEnd = Math.abs(currentDate.diff(endDate));
      if (
        (!foundIntervention && startDate.isBefore(currentDate)) ||
        (diffStart < foundIntervention?.diffStart &&
          diffEnd < foundIntervention?.diffEnd)
      ) {
        foundIntervention = {
          ...intervention,
          diffStart,
          diffEnd,
        };
      }
    }
  }

  return foundIntervention
    ? {
        name: foundIntervention.name,
        startDate: foundIntervention.startDate,
        endDate: foundIntervention.endDate,
        key: foundIntervention.key,
      }
    : null;
};

export const transformData = (data: ParticipantInterface[]) => {
  return data.map(({ bloodTest, intervention, ...rest }) => ({
    bloodTestLatest: getLatestBloodTest(bloodTest || []),
    interventionLatest: intervention?.length
      ? getIntervention(intervention)
      : null,
    bloodTest,
    intervention: intervention ? intervention : null,
    ...rest,
  }));
};

export const participantParser = (allParticipants: Record<string, any>) => {
  const obj: any = {};
  Object.keys(allParticipants).forEach((item) => {
    obj[item] = {
      ...allParticipants[item],
      intervention: Object.entries(allParticipants[item]?.intervention || {})
        .map(([key, value]: any) => ({
          ...value,
          key,
          name: value?.name,
          startDate: value?.startDate
            ? moment(value.startDate * 1000).toDate()
            : null,
          endDate: value?.endDate
            ? moment(value.endDate * 1000).toDate()
            : null,
          timestamp: value?.timestamp ? value?.timestamp : null,
        }))
        .sort((a, b) => a.timestamp - b.timestamp),
    };
  });
  return obj;
};
export const sortObjectBasedOnReqKey = (
  obj: Record<string, any> | null,
  requestedKey: string
) => {
  if (!obj) {
    return obj;
  }
  return Object.entries(obj)
    .map((item: any) => {
      return { key: item[0], ...item[1] };
    })
    .sort((objA, objB) => objA[requestedKey] - objB[requestedKey]);
};

export const convertObjectIntoArrayOfObject = (
  obj: Record<string, any> | null
) => {
  if (!obj) {
    return obj;
  }
  return Object.entries(obj).map((item: any) => {
    return { key: item[0], ...item[1] };
  });
};

export const changeKeyOfObject = (
  obj: Record<string, any> | null,
  key: string
) => {
  if (!obj) {
    return {};
  }
  const newObject: Record<string, any> = {};
  Object.values(obj).forEach((item: Record<string, any>) => {
    newObject[item[key]] = item;
  });
  return newObject;
};

export const updateKeyOfObjectUsingMultipleKey = (
  obj: Record<string, any> | null,
  firstKey: string,
  secondKey: string
) => {
  if (!obj) {
    return {};
  }
  const newObject: Record<string, any> = {};
  Object.values(obj).forEach((item: Record<string, any>) => {
    newObject[`${item[firstKey]}_${item[secondKey]}`] = item;
  });
  return newObject;
};

export const getKeySumFromAnArrayOfObject = (
  array: Array<Record<string, any>> | null,
  key: string
) => {
  if (!array) {
    return array;
  }
  return array.reduce((total, data) => {
    return total + data[key];
  }, 0);
};
export const getLastEntryFromAnArrayOfObject = (
  array: Array<Record<string, any>> | null,
  key: string
) => {
  if (!array) {
    return array;
  }
  for (let i = array.length - 1; i >= 0; i--) {
    const value = array[i][key];
    // Check if the value is greater than zero
    if (value > 0) {
      return value;
    }
  }
  return 0;
};
export const filterObjectArrayBasedOnKey = (
  array: Array<Record<string, any>> | null,
  key: string
) => {
  if (!array) {
    return array;
  }
  return array.filter((data) => {
    return !data[key];
  });
};

export const filterDataByTimeRange = (data: {
  basalData: any;
  bolusData: any;
}) => {
  const result: any = {
    wholeDayData: {
      basalData: null,
      bolusData: null,
    },
    midnightTo6amData: {
      basalData: null,
      bolusData: null,
    },
    sixAmToNoonData: {
      basalData: null,
      bolusData: null,
    },
  };
  processData(data.basalData, result, "basalData");
  processData(data.bolusData, result, "bolusData");
  return result;
};
const processData = (data: any, result: any, dataKey: keyof typeof data) => {
  if (!data) return;
  result.wholeDayData[dataKey] = data;
  const tempMidnightTo6amData: { [key: string]: any } = {};
  const tempSixAmToNoonData: { [key: string]: any } = {};
  Object.entries(data).forEach(([key, value]: [string, any], res) => {
    /* Used to get number of hours from the timestamp in local timezone 
     const hour = new Date(value.timestamp).getHours();*/
    const hour = moment.tz(value.timestamp, "America/Toronto").hour();

    if (hour >= 0 && hour < 6) {
      tempMidnightTo6amData[key] = value;
    }
    if (hour >= 6 && hour <= 23) {
      tempSixAmToNoonData[key] = value;
    }
  });

  result.midnightTo6amData[dataKey] = {
    ...tempMidnightTo6amData,
  };

  result.sixAmToNoonData[dataKey] = {
    ...tempSixAmToNoonData,
  };
  return true;
};
export const createObjectWithSpecificKeyFromArray = (
  data: Array<Record<string, any>>,
  key: string
) => {
  const obj: Record<string, any> = {};
  data.forEach((elementObj) => {
    obj[elementObj[key]] = elementObj;
  });
  return obj;
};

export const convertDateTimeToEST = (date: string | Date) => {
  if (!date) return "";
  return moment(date).tz("America/Toronto").format();
};
